<template>
     <div  class="pa-5 white">
        <block v-if="loading" />
           
        <v-row class="m-3">
            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="search.showUnbalanced"
                   
                    label="Mostrar partidas descuadradas"
                    single-line
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="search.showBalanced"
                    label="Mostrar partidas cuadradas"
                    single-line
                    hide-details
                ></v-checkbox>
            </v-col>
        </v-row>
        <date-filter class="item" v-if="!loading" @datesSelected="setDates" @filtered-data="dateFilterHandler" :dataToFilter="data" nameOfDate="History" :filterEndpoint="$API.accountlevel.getAcountingHeaderFilteredBydate" :isFilteredByOutsource="isFiltered"/>
        <br/>
        <v-btn class="btn-add" @click="handleFixCorrelatives">Corregir correlativos</v-btn>
        &nbsp;
        <v-btn class="btn-add" @click="onExportingToPdf()">Exportar a pdf</v-btn>
        &nbsp;
        <v-btn class="btn-add" @click="exportarPartidasDetalladas()">Exportar reporte detallado a pdf</v-btn>
        <GeneralFilter
        entity="lista"
        :filterEndpoint="$API.accountlevel.getAcountingHeaderFiltered"
        :search="search"
        @emptyFilter="getAcounteringHeader"
        @filtered="filterHandler"
        :twoSlots="true">
            <DxDataGrid
                ref="dataGrid"
                class="mt-5 isScrolledByGeneralFilter"
                :dataSource="itemsHistory"
                :showBorders="true"
                :showRowLines="true"
                :word-wrap-enabled="true" 
                @exporting="onExporting"
                v-if="$store.getters['rolesUser/getCustomRole'](205)"
                >
                <DxColumn cell-template="show-details"  :width="50" v-if="$store.getters['rolesUser/getCustomRole'](202)"></DxColumn>
                <!-- <DxColumn dataField="id_accounting_entry" caption="ID" :width="50"></DxColumn> -->
                <DxColumn cell-template="imbalance" :width="50"></DxColumn>
                <DxColumn dataField="ID_Accounting_item" caption="Correlativo"  ></DxColumn>
                <DxColumn dataField="EntryFormatDate" caption="Fecha"  ></DxColumn>
                <DxColumn dataField="accounting_entry_comment" caption="Concepto" ></DxColumn>
                <DxColumn dataField="accounting_entry_value_toShow" caption="Valor" ></DxColumn>
                <DxColumn dataField="typeAcountname" caption="Tipo" ></DxColumn>

                <DxPager :showPageSizeSelector="true" />
                <DxPaging :pageSize="5" />
                <template #show-details="{data}">
                    <div>
                        <a href="#" class="mx-1"
                            @click="entryTypes != undefined ? sendetails(data.data) : accountingEntrySelected(data)">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                    </div>
                </template>
                <template #imbalance="{data}">
                    <div>
                        <v-icon 
                        color="error"
                        v-if="data.data.accounting_entry_value < 0" title="Se indica la partida como descuadrada">mdi-alert</v-icon>
                        <v-icon 
                        color="success"
                        v-else title="La partida cuadra">mdi-check</v-icon>
                    </div>
                </template>
                <DxExport :enabled="true" :formats="['xlsx']" />
            </DxDataGrid>
        </GeneralFilter>
        <PrintReportePartidasDetallado :dataProps="print"></PrintReportePartidasDetallado>

        <alert
            v-model="alert.show"
            @action="alert.action"
            :type="alert.type"
            :title="alert.title"
        >
            {{ alert.message }}
        </alert>
    </div>
</template>
<script>
import DateFilter from "@/components/DateFilter";
import Block from "@/components/Block.vue";
import GeneralFilter from "@/components/GeneralFilter";
import {DxDataGrid, DxColumn,  DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
import { multipleCriteriaFilter } from "../../../helpers/object";
import { printToExcel } from "@/helpers/printToexcel";
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf'
import 'jspdf-autotable';
import PrintReportePartidasDetallado from "../../../components/ReportsAndPrintings/PrintReportePartidasDetallado.vue";
    export default {
        name: "AcountItemHistory",
        props: ['win', 'entryTypes'],
        components: {
            DxDataGrid,
            DxColumn,
            DxPager,
            DxPaging,
            GeneralFilter,
            DateFilter,
            Block,
            DxExport,
            PrintReportePartidasDetallado,
        },
        computed:{
            itemsHistory(){
                if(this.search.showUnbalanced && this.search.showBalanced){
                    return this.data;
                }
                if(this.search.showUnbalanced){
                    return this.data.filter((item) => item.accounting_entry_value < 0)
                }
                if(this.search.showBalanced){
                    return this.data.filter((item) => item.accounting_entry_value >= 0)
                }

                return [];
            },
  
        },
        data() {
            return {
                panel: 0,
                isFiltered: false,
                data: [],
                loading: true,
                search: {
                    filter: null,
                    showUnbalanced: true,
                    showBalanced: true,
                },
                NewTypesItems: null,
                Currency: JSON.parse(localStorage.getItem('branch')).Currency,
                print: {
                    show: false,
                    data: [],
                    header: {},
                },
                dates: null,
                AccountListN: [],
                alert: {
                    show: false,
                    type: "info",
                    title: "",
                    message: "",
                    action: (() => null),
                },  
            }
        },
        mounted() {
            this.loading = true;
            this.getAllAcounts()
            if(this.entryTypes == null || this.entryTypes == undefined){
                    this.getAcounteringType();
                    
            }
            else{
                this.getAcounteringHeader();
            }
        },
        methods: {
            showAlert(type, title, message, action) {
                type = type == null ? "info" : type;
                this.alert.type = type;
                this.alert.title = title;
                this.alert.message = message;
                this.alert.action = action ? action : (() => null);
                this.alert.show = true;
            },
            async getAllAcounts() {
                try {
                    const result = await this.$API.branchaccountcatalog.getActiveBranchAccountCatalog();
                    this.AccountListN = result.data;
                } catch (error) {
                    console.log(error);
                }
            },
            setDates(dates) {
                this.dates = dates
            },
            getFormattedDate() {
                const today = new Date();
                const day = String(today.getDate()).padStart(2, '0');
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
                const year = today.getFullYear();
                return `${day}/${month}/${year}`;
            },
            async exportarPartidasDetalladas() {
                this.loading = true;
                let business = JSON.parse(localStorage.getItem('user')).business;
                let start = "";
                let end = "";

                const ordererAccountingEntries = [ ...this.itemsHistory ];

                ordererAccountingEntries.sort( ( a, b ) => {
                    if( a.ID_Accounting_item < b.ID_Accounting_item ) {
                        return -1;
                    } else if( a.ID_Accounting_item > b.ID_Accounting_item ) {
                        return 1;
                    }
                    return 0;
                } );

                if (!this.dates) {
                    start = this.getFormattedDate()
                    end = this.getFormattedDate()
                }
                else {
                    start = this.dates.start
                    end = this.dates.end
                }

                const { data: result } = await this.$API.accountingentryheader.getAccountingItemsDetails( ordererAccountingEntries );

                this.print = {
                    show: true,
                    data: result,
                    accounts: this.AccountListN,
                    header: { business, start, end}
                }
                this.loading = false;
            },
            onExportingToPdf() {
                const doc = new jsPDF(); // Crear PDF en modo horizontal
                
                const lastPoint = { x: 0, y: 0 };

                exportDataGrid({
                    jsPDFDocument: doc,
                    component: this.$refs["dataGrid"].instance,
                    topLeft: { x: 0, y: 10 },
                    font: 9,
                    columnWidths: [0, 0, 35, 25, 50, 35, 35],
                    customizeCell: function (options) {
                        options.pdfCell.font.size = 7;
                    },
                    customDrawCell({ rect }) {
                        if (lastPoint.x < rect.x + rect.w) {
                            lastPoint.x = rect.x + rect.w;
                        }
                        if (lastPoint.y < rect.y + rect.h) {
                            lastPoint.y = rect.y + rect.h;
                        }
                    },
                }).then(() => {
                    var pageCount = doc.internal.getNumberOfPages();
                    for(var i = 0; i < pageCount; i++){
                        doc.setPage(i);
                        let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                        doc.setFontSize(7)
                        doc.text ('page:  ' + pageCurrent + ' / ' + pageCount,10,10);

                        const header = 'Historial de Partidas Contables';
                        const pageWidth = doc.internal.pageSize.getWidth();
                        const headerWidth = doc.getTextDimensions(header).w;
                        doc.setFontSize(10);
                        doc.text(header, (pageWidth - headerWidth) / 2, 10);
                    }
                    doc.save('Reporte de Historial de Partidas Contables.pdf'); // Guardar el archivo PDF
                }).catch(error => {
                    console.error('Error durante la exportación a PDF:', error); // Manejo de errores
                });
            },
            onExporting(e) {
                //let array = null;
                //array = ["saldo_anterior"]
                printToExcel(e, "Encabezados de Partidas Contables");
            },
            accountingEntrySelected(data) {
                const { data: { id_accounting_entry: idAccountingEntry } } = data;
                let send = this.data.find( ( { id_accounting_entry } ) => id_accounting_entry == idAccountingEntry);
                // this.$store.commit('customerData/changeView', 'tab-1'); 
                this.$store.dispatch('addWindow', {
                    name: 'Acounting',
                    component: 'Acounting',
                    unique: false,
                    meta: { accountingEntryToModify: {...send} }
                })
            },
            dateFilterHandler(response) {
                console.log( 'response ', response );
                if(response.data == null || response.data == undefined){
                    this.data = this.assignEntryTypes(response);
                    return;
                }
                this.data = this.assignEntryTypes(response.data);
            },
            getAcounteringType() {
                this.$API.branchaccountcatalog
                    .getAcounteringType()
                    .then((res) => {
                    this.NewTypesItems = res.data;
                    this.getAcounteringHeader();

                    })
                    .catch((err) => {
                    console.log(err);
                    });
            },
            sendetails(data){
                this.$emit('sendetails', data)
            },
            getHistory(){
                console.log('Obtener datos')
            },
            getAcounteringHeader(){
                this.isFiltered = false;
                this.loading = true;
                this.$API.accountlevel.getAcountingHeader().then((res) => {
                    this.data = this.assignEntryTypes(res.data);
                    this.loading = false
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            filterHandler(response) {
                this.isFiltered = true;
                this.data = this.assignEntryTypes(response.data);
            },
            assignEntryTypes(data) {
                
                for(const index in data) {
                    const {
                        id_accounting_entry_type,
                    } = data[index];
                    data[index].accounting_entry_type = !this.NewTypesItems ? this.entryTypes.find( current => current.id_accounting_entry_type === id_accounting_entry_type ).accounting_entry_code
                    : this.NewTypesItems.find( current => current.id_accounting_entry_type === id_accounting_entry_type ).accounting_entry_code;
                }

                return data;
            },
            async auxFilter(filterObj) {
                const dataToFilter = await this.$API.accountlevel.getAcountingHeaderFiltered(filterObj);
                const filterArray = [
                    "accounting_entry_type"
                ];

                return new Promise( ( resolve, reject ) => {
                    try {
                        const filterResult = multipleCriteriaFilter( filterArray, filterObj, dataToFilter.length > 0 ? dataToFilter : this.data );

                        resolve( filterResult.length > 0 ? filterResult : dataToFilter );
                    } catch (error) {
                        reject( error );
                    }
                } );
                    
            },
            async handleFixCorrelatives() {
                this.loading = true;
                try {
                    const sortedData = this.data.sort(({accounting_entry_date : a}, {accounting_entry_date : b}) => a < b ? -1 : a > b ? 1 : 0);
                    //console.log(sortedData);
    
                    let aux = 0;
                    let month = null;
                    let year = null;
    
                    const updatedData = [];
    
                    for (const item of sortedData) {
                        const arrayDate = item.EntryFormatDate.split("/");
                        if(year != arrayDate[2]) {
                            year = arrayDate[2];
                            aux = 0;
                        }
                        if(month != arrayDate[1]) {
                            month = arrayDate[1];
                            aux = 0;
                        }
                        let number = 1 + aux;
    
                        let numberString = number.toString();
    
                        if(numberString.length < 6) {
                            for(let i = numberString.length; i < 6; i++) {
                                numberString = '0' + numberString;
                            }
                        }
                        const newCorrelative = `PC${year + month + numberString}`;
                        aux++;
    
                        item.ID_Accounting_item = newCorrelative;
    
                        updatedData.push({
                            ID_Accounting_item: newCorrelative,
                            id_accounting_entry: item.id_accounting_entry
                        });
                        //console.log(newCorrelative);
                    }
                    const response = await this.$API.accountingentryheader.updateAccountingCorrelatives(updatedData);

                    if(response.success) {
                        //console.log(response);
                        this.loading = false;
                        this.showAlert("success", "Registros corregidos", `${response.message || 'Se corrigieron los correlativos de las partidas contables seleccionadas.'}`);
                        return this.data = sortedData.reverse();
                    }
                    else {
                        throw new Error('Ocurrio un error al corregir los correlativos.');
                    }
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    this.showAlert("danger", "Error", `${error.message || 'Ocurrio un error al corregir los correlativos.'}`);
                }
                
            }
        },
    }
</script>